import { Container, Nav, Navbar } from "react-bootstrap";
import { Route, Routes } from "react-router";
import { BrowserRouter, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { NewCertificate } from "./pages/registration/certificate";
import { HomePage } from "./pages/home";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { OrganizationDetailPage } from "./pages/organization/detail_page";
// import { AuthProvider, User, UserManager, useAuth } from "oidc-react";
// import { SinginOidc } from "./pages/signin-oidc";
// import { userManager } from "./api/oidc_config";
// import { useAppDispatch } from "./app/hooks";
// import { oidcSlice } from "./features/oidc/oidc";

// function App() {
//   return (
//     <AuthProvider
//       {...{
//         authority: 'https://localhost:5001/',
//         clientId: 'a123ef65-83dc-4094-a09a-76e1bec424e7',
//         redirectUri: 'https://localhost:3000/signin-oidc',
//         postLogoutRedirectUri: 'https://localhost:3000/',
//         responseType: "code",
//         scope: "openid profile",

function App() {
  // const dispatch = useAppDispatch();

  return (
    <Container fluid className="d-flex mainContainer flex-column">
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">
            {process.env.REACT_APP_APP_TITLE}
          </Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="/">صفحه اصلی</Nav.Link>
            <Nav.Link href="/newCertificate">گواهی جدید</Nav.Link>
          </Nav>
        </Container>
      </Navbar>

      <Toaster />
      {/* <AuthProvider
        userManager={userManager}
        onSignIn={(userData: User | null) => {
          if (userData) {
            dispatch(
              oidcSlice.actions.setToken({ token: userData.access_token })
            );
          }
        }}
        onSignOut={() => {
          dispatch(oidcSlice.actions.setToken({ token: "" }));
        }}
      > */}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            {/* <Route path="/signin-oidc" element={<SinginOidc />} /> */}
            <Route
              path="/OrganizationDetail/:nationalId"
              element={<OrganizationDetailPage />}
            />
            <Route path="/newCertificate" element={<NewCertificate />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      {/* </AuthProvider> */}
    </Container>
  );
}

export default App;
