import { Container } from "react-bootstrap";
import { NewRequest } from "../../components/new_request";
import { useForm } from "react-hook-form";
import { formInputs } from "../../models/request_input_model";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Suspense, lazy, useEffect } from "react";
import Loading from "react-loading";
import { useAuth } from "oidc-react";
import { oidcSlice } from "../../features/oidc/oidc";

const CSRViewer = lazy(() =>
  import("../../components/csr_viewer").then(({ CSRViewer }) => ({
    default: CSRViewer,
  }))
);

export const NewCertificate = () => {
  const useFormHook = useForm<formInputs>();
  const states = useAppSelector((state) => state.newCertificate);
  const dispatch = useAppDispatch();
  //const auth = useAuth();

  // useEffect(() => {
  //   if (auth.userData) {
  //     dispatch(
  //       oidcSlice.actions.setToken({ token: auth.userData.access_token })
  //     );
  //   }
  // }, [auth.userData]);

  // if (auth.isLoading) {
  //   return (
  //     <div className="d-flex justify-content-center align-items-center flex-grow-1 w-100 mt-5">
  //       <Loading type="spin" className="loading" height={50} width={50} />
  //     </div>
  //   );
  // }

  return (
    <Container className="mt-3 p-3 border ">
      <NewRequest useForm={useFormHook} />
      {states.csrIsReady && states.csrInfo && (
        <Suspense
          fallback={
            <div className="d-flex justify-content-center align-items-center flex-grow-1 w-100">
              <Loading type="spin" className="loading" height={50} width={50} />
            </div>
          }
        >
          <CSRViewer useForm={useFormHook} />
        </Suspense>
      )}
    </Container>
  );
};
