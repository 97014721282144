import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetFile, GetRequest, PostRequest } from "../service";
import { organizationTableRowData } from "../../models/organization_table_row_data";
import { client, endPoints } from "../../api/api";
import { ErrorResponse } from "../../models/error_response";
import { AxiosRequestConfig } from "axios";

export const getOrganizations = createAsyncThunk<
  Array<organizationTableRowData>,
  string,
  { fulfillWithValue: string; rejectValue: string }
>(
  "/register/GetOrganizations",
  async (data: string, { fulfillWithValue, rejectWithValue }) => {
    const response = await client.get(endPoints.register.getOrganizations, {
      headers: { "Accept-Language": "fa-IR" },
    });
    return response.data;
  }
);

export const getCSR = createAsyncThunk<
  string | null,
  string,
  { fulfillWithValue: string; rejectValue: string }>(
    "/organization/getCSR",
    async (data: string, { fulfillWithValue, rejectWithValue }) => {
      var config = {
        params: {
          nationalId: data,
        },
      };
      return GetRequest(
        endPoints.organization.getCSR,
        config,
        fulfillWithValue,
        rejectWithValue,
        false
      );
    }
  );

export const getDetail = createAsyncThunk<
  string | null,
  string,
  { fulfillWithValue: string; rejectValue: string }>(
    "/organization/getDetail",
    async (data: string, { fulfillWithValue, rejectWithValue }) => {
      var config = {
        params: {
          nationalId: data,
        },
      };
      return GetRequest(
        endPoints.organization.getDetail,
        config,
        fulfillWithValue,
        rejectWithValue
      );
    }
  );
export class updateTrackingNumberPacket {
  nationalId?: string;
  trackingNumber?: string;
}
export const updateTrackingNumber = createAsyncThunk<
  string,
  updateTrackingNumberPacket,
  { fulfillWithValue: string; rejectValue: string }
>(
  "/organization/updateTrackingNumber",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    return PostRequest(
      endPoints.organization.updateTrackingNumber,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      },
      fulfillWithValue,
      rejectWithValue
    );
  }
);

export const getPublicKey = createAsyncThunk<
  string | null,
  string,
  { fulfillWithValue: string; rejectValue: string }>(
    "/organization/getPublicKey",
    async (data: string, { fulfillWithValue, rejectWithValue }) => {
      var config: AxiosRequestConfig = {
        params: {
          nationalId: data,
        },
        responseType: 'blob'
      };
      return GetFile(
        endPoints.organization.getPublicKey,
        config,
        fulfillWithValue,
        rejectWithValue
      );
      
    }
  );

  export const getPrivateKey = createAsyncThunk<
  string | null,
  string,
  { fulfillWithValue: string; rejectValue: string }>(
    "/organization/getPrivateKey",
    async (data: string, { fulfillWithValue, rejectWithValue }) => {
      var config: AxiosRequestConfig = {
        params: {
          nationalId: data,
        },
        responseType: 'blob'
      };
      return GetFile(
        endPoints.organization.getPrivateKey,
        config,
        fulfillWithValue,
        rejectWithValue
      );
      
    }
  );

export class uniqueIdPacket {
  nationalId?: string;
  uniqueId?: string;
  isMain?: boolean;
}
export const insertNewUniqueId = createAsyncThunk<
  string,
  uniqueIdPacket,
  { fulfillWithValue: string; rejectValue: string }>(
    "/organization/newUniqueId",
    async (data, { fulfillWithValue, rejectWithValue }) => {
      return PostRequest(
        endPoints.organization.insertNewUniqueId,
        data,
       undefined,
        fulfillWithValue,
        rejectWithValue
      );
    }
  );
export class uploadPacket {
  nationalId?: string;
  files?: FileList | null;
}
export const uploadCertificate = createAsyncThunk<
  string,
  uploadPacket,
  { fulfillWithValue: string; rejectValue: string }>(
    "/organization/uploadCertificate",

    async (data, { fulfillWithValue, rejectWithValue }) => {
      if (data.files === undefined || data.files == null || data.files?.length < 0) {
        const error = new ErrorResponse();
        error.Code = -400;
        error.Message = "هیج گواهی برای ارسال انتخاب نشده است";
        return rejectWithValue(JSON.stringify(error))
      }
      const formData = new FormData();
      formData.append('certificateFile', data.files[0]);
      formData.append('nationalId', data.nationalId!);
      return PostRequest(
        endPoints.organization.uploadCertificate,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        },
        fulfillWithValue,
        rejectWithValue
      );
    }
  );


