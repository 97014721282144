import { AgGridReact } from "ag-grid-react";
import { useEffect } from "react";
import Loading from "react-loading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getOrganizations } from "../../services/organization/operations";

export function OrganizationDataTable() {
  const states = useAppSelector((state) => state.organizationDataTable);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getOrganizations(""));
  }, [dispatch]);

  if (states.status==="failed") {
    return (
      <div className="alert alert-danger">
        <h6>خطایی در واکشی اطلاعات رخ داده است</h6>
      </div>
    );
  }
  if (states.status==="loading") {
    return (
      <div className="d-flex justify-content-center align-items-center flex-grow-1 w-100">
        <Loading type="spin" className="loading" height={50} width={50} />
      </div>
    );
  }

  return (
    <div
      className="ag-theme-alpine w-100"
      style={{ direction: "rtl", height: 500 }}
    >
      
      <AgGridReact
        enableRtl={true}
        pagination={false}
        animateRows={true}
        rowData={states.rowData}
        columnDefs={states.columnDefs}
      />
    </div>
  );
}
