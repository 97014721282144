import { AxiosRequestConfig } from "axios";
import { ErrorResponse } from "../models/error_response";
import { ExceptionParser } from "../utility/exceptions";
import { client } from "../api/api";

export const GetRequest = async (
  endpoint: string,
  config?: AxiosRequestConfig<any>,
  fulfillWithValue?: any,
  rejectWithValue?: any,
  isJsonResult: boolean = true,
  baseAddress:string=''
) => {
  try {
    if (baseAddress!=='')
    {
      client.defaults.baseURL=baseAddress;
    }
    const response = await client.get(endpoint, config);
    if (response.data.result === "ok") {
      if (isJsonResult) {
        return fulfillWithValue(JSON.stringify(response.data.data));
      }
      return fulfillWithValue(response.data.data);
    }
    const error = new ErrorResponse();
    error.Code = -200;
    error.Message = response.data.message;
    return rejectWithValue(JSON.stringify(error));
  } catch (err: any) {
    var error = ExceptionParser(err);

    return rejectWithValue(JSON.stringify(error));
  }
};
export const GetFile = async (
  endpoint: string,
  config?: AxiosRequestConfig<any>,
  fulfillWithValue?: any,
  rejectWithValue?: any,
  isJsonResult: boolean = true
) => {
  try {
    const response = await client.get(endpoint, config);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    return fulfillWithValue(url);
  } catch (err: any) {
    var error = ExceptionParser(err);

    return rejectWithValue(JSON.stringify(error));
  }
};
export const PostRequest = async (
  endpoint: string,
  data?: any,
  config?: AxiosRequestConfig<any>,
  fulfillWithValue?: any,
  rejectWithValue?: any,
  isJsonResult: boolean = true,
  baseAddress:string=''

) => {
  try {
    if (baseAddress!=='')
    {
      client.defaults.baseURL=baseAddress;
    }
    const response = await client.post(endpoint, data, config);
    if (response.data.result === "ok") {
      if (isJsonResult) {
        return fulfillWithValue(JSON.stringify(response.data.data));
      }
      return fulfillWithValue(response.data.data);
    }
    const error = new ErrorResponse();
    error.Code = -200;
    error.Message = response.data.message;
    return rejectWithValue(JSON.stringify(error));
  } catch (err: any) {
    var error = ExceptionParser(err);

    return rejectWithValue(JSON.stringify(error));
  }
};

export const PatchRequest = async (
  endpoint: string,
  data?: any,
  config?: AxiosRequestConfig<any>,
  fulfillWithValue?: any,
  rejectWithValue?: any,
  isJsonResult: boolean = true
) => {
  try {
    const response = await client.patch(endpoint, data, config);
    if (response.data.result === "ok") {
      if (isJsonResult) {
        return fulfillWithValue(JSON.stringify(response.data.data));
      }
      return fulfillWithValue(response.data.data);
    }
    const error = new ErrorResponse();
    error.Code = -200;
    error.Message = response.data.message;
    return rejectWithValue(JSON.stringify(error));
  } catch (err: any) {
    var error = ExceptionParser(err);

    return rejectWithValue(JSON.stringify(error));
  }
};


export const PutRequest = async (
  endpoint: string,
  data?: any,
  config?: AxiosRequestConfig<any>,
  fulfillWithValue?: any,
  rejectWithValue?: any,
  isJsonResult: boolean = true
) => {
  try {
    const response = await client.put(endpoint, data, config);
    if (response.data.result === "ok") {
      if (isJsonResult) {
        return fulfillWithValue(JSON.stringify(response.data.data));
      }
      return fulfillWithValue(response.data.data);
    }
    const error = new ErrorResponse();
    error.Code = -200;
    error.Message = response.data.message;
    return rejectWithValue(JSON.stringify(error));
  } catch (err: any) {
    var error = ExceptionParser(err);

    return rejectWithValue(JSON.stringify(error));
  }
};
export const RemoveRequest = async (
  endpoint: string,
  config?: AxiosRequestConfig<any>,
  fulfillWithValue?: any,
  rejectWithValue?: any,
  isJsonResult: boolean = true
) => {
  try {
    const response = await client.delete(endpoint, config);
    if (response.data.result === "ok") {
      if (isJsonResult) {
        return fulfillWithValue(JSON.stringify(response.data.data));
      }
      return fulfillWithValue(response.data.data);
    }
    const error = new ErrorResponse();
    error.Code = -200;
    error.Message = response.data.message;
    return rejectWithValue(JSON.stringify(error));
  } catch (err: any) {
    var error = ExceptionParser(err);

    return rejectWithValue(JSON.stringify(error));
  }
};
