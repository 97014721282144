export const IsValidLegalNationalCode = (nationalCode: string) => {
    if (nationalCode.length != 11)
        return false;
    let check = Number(nationalCode.substring(10, 11));
    
    let dec = Number(nationalCode.substring(9, 10))+2;
    const coef = [29, 27, 23, 19, 17, 29, 27, 23, 19, 17];
    var sum=0;
    for(var i=0;i<10;i++)
    {
        sum+=(Number(nationalCode.substring(i,i+1)) + dec)* coef[i];
    }
    let checkSum=sum%11;
    if (checkSum==10)
    {
        checkSum=0;
    }
    return checkSum==check;

};