import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { OrganizationInfo } from "../../components/organization/info";
import { oidcSlice } from "../../features/oidc/oidc";
import { useAuth } from "oidc-react";
import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import Loading from "react-loading";
import { OrganizationTokenInfo } from "../../components/statement/token_info";
import { PostStatement } from "../../components/statement/post_statement";

export const OrganizationDetailPage = () => {
  const nationalId = useParams().nationalId!;
  // const auth = useAuth();
  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   if (auth.userData) {
  //     dispatch(
  //       oidcSlice.actions.setToken({ token: auth.userData.access_token })
  //     );
  //   }
  // }, [auth.userData]);

  // if (auth.isLoading) {
  //   return (
  //     <div className="d-flex justify-content-center align-items-center flex-grow-1 w-100 mt-5">
  //       <Loading type="spin" className="loading" height={50} width={50} />
  //     </div>
  //   );
  // }

  return (
    <Container className="mt-3">
      <div className="w-100 border p-3 rounded-4">
        <OrganizationInfo nationalId={nationalId} />
      </div>
      <div className="w-100 border p-3 mt-2 rounded-4 postStatementBox">
        {/* <OrganizationTokenInfo nationalId={nationalId} /> */}
        <PostStatement nationalId={nationalId} />
      </div>
    </Container>
  );
};
