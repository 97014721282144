import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { formInputs } from "../models/request_input_model";
import { newRequest } from "../services/registration/certificate";
import { IsValidLegalNationalCode } from "../utility/validations";
import { organizationType } from "../enums/organization_type";

interface props {
  useForm: UseFormReturn<formInputs, any>;
}

export function NewRequest(props: props) {
  const states = useAppSelector((state) => state.newCertificate);
  const dispatch = useAppDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = props.useForm;

  const submitHandler: SubmitHandler<formInputs> = (data) => {
    dispatch(
      newRequest({
        OrganizationName: data.organizationName,
        EnglishName: data.organizationENName,
        NationalId: data.nationalId,
        OrganizationType: data.organizationType,
        SecondName: data.secondName,
      })
    );
  };

  if (states.csrIsReady || states.csrInfo) {
    return <></>;
  }

  return (
    <>
      <div className="text-center bg-darkgray rounded border py-3">
        <h6 className="fw-bold mb-0">درخواست صدور مهر سازمانی</h6>
      </div>
      <div className="alert alert-info mt-3 mb-3">
        <p>لطفا اطلاعات مورد نیاز را با دقت تکمیل نمایید.</p>
        <p>
          پس از تکمیل و ثبت درخواست اطلاعات مورد نیاز برای ارسال به مرکز صدور
          گواهی الکترونیکی میانی عام
          <a href="https://gica.ir/"> (gica.ir)</a> اطلاعات مربوط به درخواست
          گواهی برای شما ایجاد خواهد شد و قابل انتقال میباشد.
        </p>
        <p>
          پس از تکمیل اطلاعات در سامانه جی کا و پرداخت وجه هزینه صدور گواهی کد
          رهگیری را ذخیره نمایید.
        </p>
      </div>
      <Form className="mt-3 text-start" onSubmit={handleSubmit(submitHandler)}>
        <Row className="g-3">
          <Col xs={12} md={6} lg={3}>
            <Form.Group className="mb-3" controlId="nationalId">
              <Form.Label>شناسه</Form.Label>
              <Form.Control
                placeholder="شناسه ملی"
                className="text-center"
                type="number"
                {...register("nationalId", {
                  required: true,
                  minLength: 11,
                  maxLength: 11,
                  validate: {
                    checkCode: (v) =>
                      IsValidLegalNationalCode(v) ||
                      "شناسه وارد شده معتبر نیست",
                  },
                })}
              />
              {errors.nationalId && (
                <Form.Text className="text-danger">
                  {errors.nationalId.type === "required" &&
                    "وارد کردن شناسه ملی الزامیست"}
                  {errors.nationalId.type === "minLength" &&
                    "شناسه ملی میبایست 11 رقم باشد"}
                  {errors.nationalId.message}
                  {errors.nationalId.type === "maxLength" &&
                    "شناسه ملی میبایست 11 رقم باشد"}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Form.Group className="mb-3" controlId="organizationType">
              <Form.Label>نوع سازمان</Form.Label>
              <Form.Select
                {...register("organizationType", { required: true })}
              >
                {errors.organizationType && (
                  <Form.Text className="text-danger">
                    نوع سازمان را مشخص نمایید.
                  </Form.Text>
                )}
                <option value={organizationType.nonGovernmental}>غیر وابسته به دولت</option>
                <option value={organizationType.governmental}>وابسته به دولت</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Form.Group className="mb-3" controlId="organizationEnName">
              <Form.Label>نام انگلیسی سازمان</Form.Label>
              <Form.Control
                placeholder="Organization name"
                className="text-center"
                {...register("organizationENName", {
                  required: true,
                  minLength: 3,
                })}
              />
              {errors.organizationENName && (
                <Form.Text className="text-danger">
                  لطفا نام انگلیسی سازمان را بطور کامل وارد نمایید
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Form.Group className="mb-3" controlId="organizationName">
              <Form.Label>نام سازمان</Form.Label>
              <Form.Control
                placeholder="نام سازمان"
                className="text-center"
                {...register("organizationName", {
                  required: true,
                  minLength: 3,
                })}
              />
              {errors.organizationName && (
                <Form.Text className="text-danger">
                  لطفا نام سازمان را بطور کامل وارد نمایید
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className="g-3">
          <p>
            درصورتی که سازمان دارای مهر دیگری میباشد لطفا یک نام زیر مجموعه برای
            آن انتخاب نمایید ( مانند : واحد فروش ، واحد تولید، واحد خواهران
            و.... )
          </p>
          <Col xs={12} md={6} lg={3}>
            <Form.Group className="mb-3" controlId="secondName">
              <Form.Label>نام زیر مجموعه سازمان</Form.Label>
              <Form.Control
                placeholder="نام زیر مجموعه سازمان"
                className="text-center"
                {...register("secondName")}

              />
              {errors.secondName && (
                <Form.Text className="text-danger">
                  لطفا نام زیر مجموعه سازمان را بطور کامل وارد نمایید
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className="g-3">
          <Col xs={12}>
            <Button type="submit" variant="success" disabled={states.loading}>
              {states.loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-3"
                />
              )}
              <span>ثبت درخواست</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
