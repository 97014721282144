import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Loading from "react-loading";
import { getDetail } from "../../services/organization/operations";

export const OrganizationInfo = ({ nationalId }: { nationalId: string }) => {
    const dispatch = useAppDispatch();
    const states = useAppSelector((state) => state.organizationOperations);

    useEffect(() => {
        dispatch(getDetail(nationalId));
    },[nationalId]);

    if (states.status === "loading") {
        return (
            <div className="d-flex justify-content-center align-items-center flex-grow-1 w-100">
                <Loading type="spin" color="navy" height={50} width={50} />
            </div>
        );
    }
    if (states.status === "failed") {
        return (
            <div className="alert alert-danger">
                <p>متاسفانه خطایی در فراخوانی اطلاعات رخ داده است، لطفا مجدداً تلاش کنید</p>

            </div>
        );
    }
    if (states.status === "succeeded") {

        const info = states.info!;
        return (
            <div className="d-flex flex-row w-100 ">
                <div className="flex-grow-1">
                    <h4 className="text-primary">{info?.persianName}</h4>
                    <span className="display-10">{info.englishName}</span><br />
                    <span className="display-11">{info.secondName}</span><br />
                </div>
                <span className="text-danger me-auto">{info.requestStatusTitle}</span>

            </div>
        )
    }
    return (
        <div className="alert alert-danger">
            <p>هیچ وضعیتی برای این درخواست یافت نشد</p>

        </div>
    );
}