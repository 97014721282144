import { Container } from "react-bootstrap";
import { OrganizationDataTable } from "../components/organization/data_grid";
import { useAuth } from "oidc-react";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
// import { oidcSlice } from "../features/oidc/oidc";
import Loading from "react-loading";

export const HomePage = () => {
  // const auth = useAuth();
  // const dispatch = useAppDispatch();
  // const states = useAppSelector((state) => state.oidc);

  // useEffect(() => {
  //   if (auth.userData) {
  //     dispatch(
  //       oidcSlice.actions.setToken({ token: auth.userData.access_token })
  //     );
  //   }
  // }, [auth.userData]);

  // if (auth.isLoading) {
  //   return (
  //     <div className="d-flex justify-content-center align-items-center flex-grow-1 w-100 mt-5">
  //       <Loading type="spin" className="loading" height={50} width={50} />
  //     </div>
  //   );
  // }

  return (
    <Container>
      <h2 className="mt-5 mb-3">{process.env.REACT_APP_APP_TITLE}</h2>
      <OrganizationDataTable />
    </Container>
  );
};
