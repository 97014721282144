import { createAsyncThunk } from "@reduxjs/toolkit";
import { RequestModel } from "../../models/request_model";
import { ExceptionParser } from "../../utility/exceptions";
import { OrganizationModel } from "../../models/organization_model";
import { ErrorResponse } from "../../models/error_response";
import { client, endPoints } from "../../api/api";


export const newRequest = createAsyncThunk<OrganizationModel, RequestModel, {fulfillWithValue:OrganizationModel, rejectValue: string }>(
  "/newCertificate/newRequest",
  async (data: RequestModel, { fulfillWithValue,rejectWithValue }) => {
    try {
      const response = await client.post(endPoints.register.newRequest, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })

      if (response.data.result==="ok")
      {
        return fulfillWithValue(response.data.data);
      }
      const error=new ErrorResponse();
      error.Code=-200;
      error.Message=response.data.message;
      return rejectWithValue(JSON.stringify(error))
    }
    catch (err: any) {
      var error = ExceptionParser(err);
  
      return rejectWithValue(JSON.stringify(error));
    }

  }
);

export const checkGetHealth = createAsyncThunk(
  "/newCertificate/getHealth",
  async () => {
    const res = await client.get(endPoints.register.getHealth);
    return res;
  }
);

export const checkPostHealth = createAsyncThunk(
  "/newCertificate/postHealth",
  async () => {
    const res = await client.post(endPoints.register.postHealth);
    return res;
  }
);


