import React, { ChangeEvent, useEffect } from "react"
import { Button, Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { PostStatementOperationsSlice } from "../../features/statement/post_statement_operations";
import { postStatement } from "../../services/statement/statement";
import { PostStatementModel } from "../../models/post_statement_model";

export const PostStatement = ({ nationalId }: { nationalId: string }) => {
    const dispatch = useAppDispatch();
    const states = useAppSelector((state) => state.postStatement);


    useEffect(() => {
        dispatch(PostStatementOperationsSlice.actions.setNationalId(nationalId));
    }, [nationalId]);

    const onSandBoxModeChanged = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(PostStatementOperationsSlice.actions.changeSandBoxMode(e.target.checked));
    }
    const submitHandler = () => {
        let data = new PostStatementModel();
        data.IsSandBox = states.isSandBox;
        data.NationalId = states.nationalId;
        data.Files = states.files;
        dispatch(postStatement(data));
    }
    const fileSelectorHandler = (e: ChangeEvent<HTMLInputElement>) => {
        var files = e.target.files;
        dispatch(
            PostStatementOperationsSlice.actions.setFile({ files })
        );
    }

    return (
        <>
            <div className="d-flex align-items-start flex-column w-100">
                <div >
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="شناسه اصلی"
                        value="true"
                        onChange={onSandBoxModeChanged}
                    />
                    <h4 className="d-none alertMessage">شما در حال ارسال اطلاعات   به سرور اصلی مودیان میباشید، لطفا دقت لازم را مبذول فرمایید.</h4>
                </div>
                <div className="d-flex flex-row w-100 mt-2">
                    <div className="col-8">
                        <Form.Control
                            type="file"
                            accept=".xlsx"
                            onChange={fileSelectorHandler}

                        />
                    </div>
                    <div className="col-2 ms-2">
                        <Button
                            className="text-white"
                            variant="primary"
                            onClick={submitHandler}
                        >
                            ارسال و ثبت
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}