import axios from "axios";

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;
const statement_prefix=`${process.env.REACT_APP_STATEMENT_API_PREFIX}/v${process.env.REACT_APP_API_VERSION}`;
const base_prefix=`${process.env.REACT_APP_API_PREFIX}/v${process.env.REACT_APP_API_VERSION}`;

export const client = axios.create({
  baseURL: baseUrl,
});
// client.defaults.headers.common['Authorization'] = AUTH_TOKEN;
client.defaults.headers.common[`Accept-Language`] = `fa-IR`;

export const endPoints = {
  organization: {
    getCSR: `${base_prefix}/organization/getCSR`,
    getDetail: `${base_prefix}/organization/getDetail`,
    updateTrackingNumber: `${base_prefix}/organization/UpdateTrackingNumber`,
    uploadCertificate: `${base_prefix}/organization/uploadCertificate`,
    getPublicKey: `${base_prefix}/organization/getPublicKey`,
    getPrivateKey: `${base_prefix}/organization/getPrivateKey`,
    insertNewUniqueId: `${base_prefix}/organization/insertNewUniqueId`,
  },
  statement: {
    getToken: `${statement_prefix}/operations/getToken`,
    postStatement: `${statement_prefix}/operations/postStatement`,
  },
  register: {
    getHealth: `${base_prefix}/register/getHealth`,
    postHealth: `${base_prefix}/register/postHealth`,
    newRequest: `${base_prefix}/register/AddNewOrganization`,
    getOrganizations: `${base_prefix}/register/GetOrganizations`,
  },
};
