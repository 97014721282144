import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetRequest, PostRequest } from "../service";
import { client,endPoints } from "../../api/api";
import { PostStatementModel } from "../../models/post_statement_model";
import { AxiosRequestConfig } from "axios";
import { ErrorResponse } from "../../models/error_response";

export class statementOrganizationInfo {
  nationalId?: string;
  isSandBox?: boolean;
}

export const getToken = createAsyncThunk<
  string | null,
  statementOrganizationInfo,
  { fulfillWithValue: string; rejectValue: string }>(
    "/statement/getToken",
    async (data: statementOrganizationInfo, { fulfillWithValue, rejectWithValue }) => {
      var config = {
        params: {
          nationalId: data.nationalId,
          isSandBox: data.isSandBox,
        },
        headers:{
          "mode":"SandBox"
        }
      };
      return GetRequest(
        endPoints.statement.getToken,
        config,
        fulfillWithValue,
        rejectWithValue,
        true, 
        process.env.REACT_APP_STATEMENT_BASE_URL
      );
    }
  );

  export const postStatement = createAsyncThunk<
  string | null,
  PostStatementModel,
  { fulfillWithValue: string; rejectValue: string }>(
    "/statement/postStatement",
    async (data: PostStatementModel, { fulfillWithValue, rejectWithValue }) => {
      
      if (data.Files === undefined || data.Files == null || data.Files?.length < 0) {
        const error = new ErrorResponse();
        error.Code = -400;
        error.Message = "هیج فایلی برای ارسال انتخاب نشده است";
        return rejectWithValue(JSON.stringify(error))
      }
      const formData = new FormData();
      formData.append('excelFiles', data.Files[0]);
      var config = {
        headers:{
          "mode":"SandBox",
          'Content-Type': 'multipart/form-data',
        }
      };
      return PostRequest(
        endPoints.statement.postStatement,
        data,
        config,
        fulfillWithValue,
        rejectWithValue,
        true, 
        process.env.REACT_APP_STATEMENT_BASE_URL
      );
    }
  );
