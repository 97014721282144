import { createSlice } from "@reduxjs/toolkit";

interface states {
  token: string;
}

const initialState: states = {
  token: "",
};

export const oidcSlice = createSlice({
  name: "oidc slice",
  initialState,
  reducers: {
    setToken: (state, params: { payload: { token: string } }) => {
      state.token = params.payload.token;
    },
  },
});
