import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { NewRegisterCertificateSlice } from "../features/register_certificates/register_new_certificate";
import { OrganizationOperationsSlice } from "../features/organization/operations";
import { organizationDataTableSlice } from "../features/organization/datatable_operations";
import { oidcSlice } from "../features/oidc/oidc";
import { StatementOperationsSlice } from "../features/statement/statement_operations";
import { PostStatementOperationsSlice } from "../features/statement/post_statement_operations";

export const store = configureStore({
  reducer: {
    newCertificate: NewRegisterCertificateSlice.reducer,
    organizationOperations: OrganizationOperationsSlice.reducer,
    statementOperations: StatementOperationsSlice.reducer,
    organizationDataTable: organizationDataTableSlice.reducer,
    postStatement:PostStatementOperationsSlice.reducer,
    oidc: oidcSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<unknown>
>;
