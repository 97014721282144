import { createSlice } from "@reduxjs/toolkit"
import { getCSR,getDetail } from "../../services/organization/operations";
import { toast } from "react-hot-toast";
import { OrganizationModel } from "../../models/organization_model";

interface OrganizationState {
    data?: string | null;
    info?:OrganizationModel | null,
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: OrganizationState = {
    data: null,
    info:null,
    status: 'idle'
}

export const OrganizationOperationsSlice = createSlice({
    name: "organizationOperations",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCSR.pending, (currentState) => {
            currentState.status = 'loading';
        });
        builder.addCase(getCSR.fulfilled, (currentState, action) => {
            currentState.status = 'succeeded';
            currentState.data = action.payload;
            navigator.clipboard.writeText(action.payload ?? "");
            toast.success("اطلاعات گواهی دریافت شد");
        });
        builder.addCase(getCSR.rejected, (currentState, action) => {
            currentState.status = 'failed';
            currentState.data = null;
            const error = JSON.parse(action.payload ?? "");
            const innerMessage = error.Message.value ?? error.Message;
            const message = `(${error.Code}) ${innerMessage}`;
            toast.error(message);
        });


        builder.addCase(getDetail.pending, (currentState) => {
            currentState.status = 'loading';
        });
        builder.addCase(getDetail.fulfilled, (currentState, action) => {
            currentState.status = 'succeeded';
            currentState.info =JSON.parse(action.payload??"");
        });
        builder.addCase(getDetail.rejected, (currentState, action) => {
            
            currentState.status = 'failed';
            currentState.info = null;
            const error = JSON.parse(action.payload ?? "");
            const innerMessage = error.Message.value ?? error.Message;
            const message = `(${error.Code}) ${innerMessage}`;
            toast.error(message);
        });

    }

});