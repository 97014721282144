//import { AuthProvider, User } from "oidc-react";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { userManager } from "./api/oidc_config";
import { store } from "./app/store";
import "./css/iran-font.css";
import "./css/tax.rtl.css";
import { useAppDispatch } from "./app/hooks";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
