import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { organizationDataTableSlice } from "../../features/organization/datatable_operations";
import {
  uploadCertificate, getCSR, uploadPacket, updateTrackingNumberPacket,
  updateTrackingNumber, getPublicKey, uniqueIdPacket, insertNewUniqueId, getPrivateKey
} from "../../services/organization/operations";


export function OrganizationActionRenderer(params: any) {
  const dispatch = useAppDispatch();
  const states = useAppSelector((state) => state.organizationDataTable);
  const navigate = useNavigate();
  const { setUniqueId, setTrackingNumber, setIsMainUniqueId } = organizationDataTableSlice.actions;


  const detailPath = "/OrganizationDetail/" + params.data.nationalId;
  const nationalId = params.data.nationalId;
  const routeChange = (path: string) => {
    navigate(path);
  };

  const getCSRHandler = () => {
    dispatch(getCSR(params.data.nationalId));
  };
  const getPubKeyHandler = () => {
    dispatch(getPublicKey(params.data.nationalId));
  };
  const getPrivateKeyHandler = () => {
    dispatch(getPrivateKey(params.data.nationalId));
  };


  const UploadCertificateHandler = () => {
    dispatch(organizationDataTableSlice.actions.showUploadModal(nationalId));
  };
  const TrackingNumberHandler = () => {
    dispatch(organizationDataTableSlice.actions.showTrackingNumberModal(nationalId));
  };
  const UniqueIdHandler = () => {
    dispatch(organizationDataTableSlice.actions.showUniqueIdModal(nationalId));
  };

  const fileSelectorHandler = (e: ChangeEvent<HTMLInputElement>) => {
    var files = e.target.files;
    dispatch(
      organizationDataTableSlice.actions.setFile({ files })
    );
  }
  const submitHandler = () => {
    var data = new uploadPacket();
    data.nationalId = states.nationalId!;

    data.files = states.files;
    dispatch(uploadCertificate(data));
  }

  const submitTrackingNumberHandler = () => {
    var data = new updateTrackingNumberPacket();
    data.nationalId = states.nationalId!;
    data.trackingNumber = states.trackingNumber;
    dispatch(updateTrackingNumber(data));
  }

  const submitUniqueIdHandler = () => {
    var data = new uniqueIdPacket();
    data.nationalId = states.nationalId!;
    data.uniqueId = states.uniqueId;
    data.isMain = states.isMainUniqueId;
    dispatch(insertNewUniqueId(data));
  }

  return (
    <div>
      <Modal
        show={states.showUploadModal}
        onHide={() =>
          dispatch(organizationDataTableSlice.actions.hideUploadModal())
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>بارگذاری گواهی</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="file"
            accept=".crt"
            onChange={fileSelectorHandler}
          />
          <Button
            className="mt-2"
            variant="primary"
            onClick={submitHandler}
          >
            ارسال و ثبت
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() =>
              dispatch(organizationDataTableSlice.actions.hideUploadModal())
            }
          >
            بستن
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={states.showTrackingNumber}
        onHide={() =>
          dispatch(organizationDataTableSlice.actions.hideTrackingNumberModal())
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>ثبت کد رهگیری</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="organizationName">
            <Form.Label>کد رهگیری</Form.Label>
            <Form.Control
              placeholder="کد رهگیری"
              className="text-center"
              onChange={(e: ChangeEvent<HTMLInputElement>) => { dispatch(setTrackingNumber(e.target.value)) }}

            />
          </Form.Group>
          <Button
            className="mt-2"
            variant="primary"
            onClick={submitTrackingNumberHandler}
          >
            ثبت کد رهگیری
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() =>
              dispatch(organizationDataTableSlice.actions.hideUploadModal())
            }
          >
            بستن
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={states.showUniqueIdModal}
        onHide={() =>
          dispatch(organizationDataTableSlice.actions.hideUniqueIdModal())
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>ثبت شناسه یکتا</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="uniqueId">
            <Form.Label>شناسه یکتا</Form.Label>
            <Form.Control
              placeholder="شناسه یکتا"
              className="text-center"
              onChange={(e: ChangeEvent<HTMLInputElement>) => { dispatch(setUniqueId(e.target.value)) }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="mainUniqueId">
            <Form.Check
              type="switch"
              id="custom-switch"
              label="شناسه اصلی"
              value="true"
              checked={states.isMainUniqueId}
              onChange={(e: ChangeEvent<HTMLInputElement>) => { dispatch(setIsMainUniqueId(e.target.checked)) }}

            />
          </Form.Group>
          <Button
            className="mt-2"
            variant="primary"
            onClick={submitUniqueIdHandler}
          >
            ثبت شناسه یکتا
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() =>
              dispatch(organizationDataTableSlice.actions.hideUniqueIdModal())
            }
          >
            بستن
          </Button>
        </Modal.Footer>
      </Modal>

      <ButtonGroup aria-label="Operations">
        <Button
          variant="success"
          size="sm"
          onClick={getCSRHandler}
          title="کپی درخواست"
        >
          <FontAwesomeIcon icon={icon({ name: "copy" })} />
        </Button>

        <Button variant="orange" size="sm" title="ثبت کد رهگیری"
          onClick={TrackingNumberHandler}
        >
          <FontAwesomeIcon icon={icon({ name: "save" })} />
        </Button>
        <Button
          variant="lightblue"
          size="sm"
          title="بارگذاری گواهی"
          onClick={UploadCertificateHandler}
        >
          <FontAwesomeIcon icon={icon({ name: "upload" })} />
        </Button>
        <Button variant="liver" size="sm"
          onClick={getPubKeyHandler}
          title="کلید عمومی" >
          <FontAwesomeIcon icon={icon({ name: "key" })} />
        </Button>
        <Button variant="warning" size="sm"
          onClick={getPrivateKeyHandler}
          title="کلید خصوصی" >
          <FontAwesomeIcon icon={icon({ name: "key" })} />
        </Button>
        <Button variant="teal" size="sm" title="ثبت شناسه یکتا"
          onClick={UniqueIdHandler}
        >
          <FontAwesomeIcon icon={icon({ name: "fingerprint" })} />
        </Button>
        <Button
          variant="secondary"
          size="sm"
          title="جزییات و صورت حسابها"
          onClick={() => routeChange(detailPath)}
        >
          <FontAwesomeIcon icon={icon({ name: "circle-info" })} />
        </Button>
      </ButtonGroup>
    </div>
  );
}
