import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-hot-toast";
import { getToken } from "../../services/statement/statement";

interface StatementState {
    data?: string | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: StatementState = {
    data: null,
    status: 'idle'
}

export const StatementOperationsSlice = createSlice({
    name: "statementOperations",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getToken.pending, (currentState) => {
            currentState.status = 'loading';
        });
        builder.addCase(getToken.fulfilled, (currentState, action) => {
            currentState.status = 'succeeded';
            currentState.data = action.payload;
            toast.success("اطلاعات توکن دریافت شد");
        });
        builder.addCase(getToken.rejected, (currentState, action) => {
            currentState.status = 'failed';
            currentState.data = null;
            const error = JSON.parse(action.payload ?? "");
            const innerMessage = error.Message.value ?? error.Message;
            const message = `(${error.Code}) ${innerMessage}`;
            toast.error(message);
        });
    }

});