import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-hot-toast";
import { PostStatementModel } from "../../models/post_statement_model";
import { postStatement } from "../../services/statement/statement";

interface StatementState {
    isSandBox: boolean,
    nationalId: string,
    files: FileList | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: StatementState = {
    isSandBox: true,
    nationalId: "",
    files: null,

    status: 'idle'
}

export const PostStatementOperationsSlice = createSlice({
    name: "statementOperations",
    initialState: initialState,
    reducers: {
        setNationalId: (state, action) => {
            state.nationalId = action.payload;
        },
        setFile: (state, action) => {
            state.files = action.payload.files;
        },
        changeSandBoxMode: (state, action) => {
            var box = document.querySelector(".postStatementBox");
            var alertMessage = document.querySelector(".alertMessage");
            if (action.payload) {
                box?.classList.add("alert", "alert-danger");
                alertMessage?.classList.remove("d-none");
                state.isSandBox = false;
            } else {
                box?.classList.remove("alert", "alert-danger");
                alertMessage?.classList.add("d-none");
                state.isSandBox = true;
            }
        },

    },
    extraReducers: (builder) => {
        builder.addCase(postStatement.pending, (currentState) => {
            currentState.status = 'loading';
        });
        builder.addCase(postStatement.fulfilled, (currentState, action) => {
            currentState.status = 'succeeded';
            toast.success("اطلاعات به سرور سامانه مودیان ارسال شد.");
        });
        builder.addCase(postStatement.rejected, (currentState, action) => {
            currentState.status = 'failed';
            const error = JSON.parse(action.payload ?? "");
            const innerMessage = error.Message.value ?? error.Message;
            const message = `(${error.Code}) ${innerMessage}`;
            toast.error(message);
        });
    }

});