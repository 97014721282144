import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { organizationTableColumnModel } from "../../models/organization_table_column_model";
import { organizationTableRowData } from "../../models/organization_table_row_data";
import { OrganizationActionRenderer } from "../../components/organization/organization_action_renderer";
import { uploadCertificate, getOrganizations, updateTrackingNumber, getPublicKey, insertNewUniqueId, getPrivateKey } from "../../services/organization/operations";
import toast from "react-hot-toast";

interface state {
  columnDefs: Array<organizationTableColumnModel>;
  rowData: Array<organizationTableRowData>;
  files: FileList | null;
  showUploadModal: boolean;
  showTrackingNumber: boolean;
  showUniqueIdModal: boolean;

  trackingNumber: string;
  uniqueId: string;
  isMainUniqueId: boolean;

  nationalId: string | null;
  status: "idle" | "loading" | "dataLoaded" | "uploaded" | "success" | "failed";
}

const initialState: state = {
  columnDefs: [
    { field: "nationalId", headerName: "شناسه ملی", filter: true, width: 140 },
    {
      field: "persianName",
      headerName: "نام سازمان",
      filter: true,
      width: 240,
    },
    {
      field: "englishName",
      headerName: "نام انگلیسی",
      filter: false,
      width: 240,
    },
    {
      field: "trackingNumber",
      headerName: "کد رهگیری",
      filter: true,
      width: 150,
    },
    {
      field: "requestStatusTitle",
      headerName: "وضعیت",
      filter: true,
      width: 240,
    },
    {
      field: "operation",
      headerName: "عملیات",
      cellRenderer: OrganizationActionRenderer,
      width: 280,
      filter: false,
    },
  ],
  rowData: [],
  showUploadModal: false,
  showTrackingNumber: false,
  showUniqueIdModal: false,
  trackingNumber: "",
  nationalId: "",
  uniqueId: "",
  isMainUniqueId: false,
  files: null,
  status: "loading"
};

export const organizationDataTableSlice = createSlice({
  name: "organization data table slice",
  initialState,
  reducers: {
    setTrackingNumber: (state, action) => {
      state.trackingNumber = action.payload;
    },
    setUniqueId: (state, action) => {
      state.uniqueId = action.payload;
    },
    setIsMainUniqueId: (state, action) => {
      state.isMainUniqueId = action.payload;
    },
    showUploadModal: (state, action) => {
      state.nationalId=action.payload;
      state.showUploadModal = true;
    },
    showTrackingNumberModal: (state, action) => {
      state.nationalId = action.payload;
      state.showTrackingNumber = true;
    },
    showUniqueIdModal: (state, action) => {
      state.nationalId = action.payload;
      state.showUniqueIdModal = true;
    },
    setFile: (state, action: PayloadAction<{ files: FileList | null }>) => {
      state.files = action.payload.files;
    },
    hideUploadModal: (state) => {
      state.nationalId = "";
      state.showUploadModal = false;
    },
    hideUniqueIdModal: (state) => {
      state.nationalId = "";
      state.uniqueId = "";
      state.isMainUniqueId = false;
      state.showUniqueIdModal = false;
    },
    hideTrackingNumberModal: (state) => {
      state.trackingNumber = "";
      state.showTrackingNumber = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganizations.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getOrganizations.fulfilled, (state, action) => {
      state.status = 'dataLoaded';
      state.rowData = action.payload;
    });
    builder.addCase(getOrganizations.rejected, (state, action) => {
      state.status = 'failed';
      const error = JSON.parse(action.payload ?? "");
      const innerMessage = error.Message.value ?? error.Message;
      const message = `(${error.Code}) ${innerMessage}`;
      toast.error(message);

    });


    builder.addCase(uploadCertificate.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(uploadCertificate.fulfilled, (state, action) => {
      state.status = 'uploaded';
      state.showUploadModal = false;
      toast.success("فایل گواهی ثبت شد");

    });
    builder.addCase(uploadCertificate.rejected, (state, action) => {
      state.status = 'dataLoaded';
      state.showUploadModal = false;

      const error = JSON.parse(action.payload ?? "");
      const innerMessage = error.Message.value ?? error.Message;
      const message = `(${error.Code}) ${innerMessage}`;
      toast.error(message);

    });


    builder.addCase(updateTrackingNumber.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(updateTrackingNumber.fulfilled, (state, action) => {
      state.status = 'dataLoaded';
      state.showTrackingNumber = false;
      toast.success("کد رهگیری ثبت شد");

    });
    builder.addCase(updateTrackingNumber.rejected, (state, action) => {
      state.status = 'dataLoaded';
      state.showTrackingNumber = false;

      const error = JSON.parse(action.payload ?? "");
      const innerMessage = error.Message.value ?? error.Message;
      const message = `(${error.Code}) ${innerMessage}`;
      toast.error(message);

    });


    builder.addCase(getPublicKey.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getPublicKey.fulfilled, (state, action) => {
      state.status = 'dataLoaded';
      const link = document.createElement('a');
      link.href = action.payload!;
      link.download = `pubKey_(${state.nationalId}).txt`;
      link.click();
      window.URL.revokeObjectURL(action.payload!);
      toast.success("کد عمومی دریافت شد");

    });
    builder.addCase(getPublicKey.rejected, (state, action) => {
      state.status = 'dataLoaded';
      state.showTrackingNumber = false;

      const error = JSON.parse(action.payload ?? "");
      const innerMessage = error.Message.value ?? error.Message;
      const message = `(${error.Code}) ${innerMessage}`;
      toast.error(message);

    });



    builder.addCase(getPrivateKey.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getPrivateKey.fulfilled, (state, action) => {
      state.status = 'dataLoaded';
      const link = document.createElement('a');
      link.href = action.payload!;
      link.download = `privateKey_(${state.nationalId}).txt`;
      link.click();
      window.URL.revokeObjectURL(action.payload!);
      toast.success("کد خصوصی دریافت شد");

    });
    builder.addCase(getPrivateKey.rejected, (state, action) => {
      state.status = 'dataLoaded';
      state.showTrackingNumber = false;

      const error = JSON.parse(action.payload ?? "");
      const innerMessage = error.Message.value ?? error.Message;
      const message = `(${error.Code}) ${innerMessage}`;
      toast.error(message);

    });


    builder.addCase(insertNewUniqueId.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(insertNewUniqueId.fulfilled, (state, action) => {
      state.status = 'dataLoaded';
      toast.success("شناسه یکتا ثبت شد");

    });
    builder.addCase(insertNewUniqueId.rejected, (state, action) => {
      state.status = 'dataLoaded';
      state.showTrackingNumber = false;

      const error = JSON.parse(action.payload ?? "");
      const innerMessage = error.Message.value ?? error.Message;
      const message = `(${error.Code}) ${innerMessage}`;
      toast.error(message);

    });



  },
});
