import { createSlice } from "@reduxjs/toolkit";
import { newRequest } from "../../services/registration/certificate";
import { ErrorResponse } from "../../models/error_response";
import { IsValidLegalNationalCode } from "../../utility/validations";
import { CertificateType } from "../../enums/certificate_type_enum";
import { toast } from "react-hot-toast";

interface state {

  organizationName: string;
  englishName: string;
  nationalId: string;
  organizationType: number;
  secondName: string;

  organizationNameError: string | null;
  englishOrganizationNameError: string | null;
  organizationTypeError: string | null;
  nationalIdError: string | null;
  loading: boolean;
  error: ErrorResponse | undefined;
  csrIsReady: boolean;
  csrInfo: string;
}

const initialStates: state = {
  organizationName: '',
  englishName: '',
  nationalId: '',
  organizationType: 1,
  secondName: '',

  organizationNameError: "",
  englishOrganizationNameError: "",
  organizationTypeError: null,
  nationalIdError: "",
  loading: false,
  error: undefined,

  csrIsReady: false,
  csrInfo: '',
};

export const NewRegisterCertificateSlice = createSlice({
  name: "newRequestSlice",
  initialState: initialStates,
  reducers: {
    setNewRequest: (state) => {
      state.organizationName = '';
      state.englishName = '';
      state.nationalId = '';
      state.organizationType = 1;
      state.organizationNameError = '';
      state.englishOrganizationNameError = '';
      state.organizationTypeError = null;
      state.nationalIdError = '';
      state.loading = false;
      state.error = undefined;
      state.csrIsReady = false;
      state.csrInfo = '';

    },
    setOrganizationName: (state, action) => {
      state.organizationName = action.payload;
      if (action.payload.length < 3) {
        state.organizationNameError =
          "لطفا نام سازمان را بطور کامل وارد نمایید";
      } else {
        state.organizationNameError = null;
      }
    },
    setSecondName: (state, action) => {
      state.secondName = action.payload;
    },
    setEnglishOrganizationName: (state, action) => {
      state.englishName = action.payload;
      if (action.payload.length < 3) {
        state.englishOrganizationNameError =
          "لطفا نام انگلیسی سازمان را بطور کامل وارد نمایید";
      } else {
        state.englishOrganizationNameError = null;
      }
    },
    setNationalId: (state, action) => {
      state.nationalId = action.payload;
      if (action.payload.length !== 11) {
        state.nationalIdError = "شناسه ملی میبایست 11 رقم باشد";
      }
      else if (!IsValidLegalNationalCode(action.payload)) {
        state.nationalIdError = "شناسه وارد شده معتبر نیست";
      }
      else {
        state.nationalIdError = null;
      }
    },
    setOrganizationType: (state, action) => {
      state.organizationType = action.payload;
    },
    setOrganizationTypeError: (
      state,
      action: { payload: { error: string | null } }
    ) => {
      state.organizationTypeError = action.payload.error;
    },
    setLoading: (state, action: { payload: boolean }) => {
      state.loading = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(newRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(newRequest.fulfilled, (state, action) => {
      state.loading = false;

      const data = action.payload;
      const certificates = data.certificates?.filter(x => x.certificateType === CertificateType.CSR);
      if (certificates && certificates.length === 1 && certificates[0].value !== "") {
        toast.success("اطلاعات با موفقیت ثبت شد");

        state.csrIsReady = true;
        state.csrInfo = certificates[0].value;
      }
      state.error = undefined;
    });
    builder.addCase(newRequest.rejected, (state, action) => {
      state.loading = false;
      state.csrIsReady = false;
      const error = JSON.parse(action.payload ?? "");
      const innerMessage = error.Message.value ?? error.Message;
      const message = `(${error.Code}) ${innerMessage}`;
      toast.error(message);

      state.error = error;
    });
  },
});
