import { ErrorResponse } from "../models/error_response";

export const ExceptionParser = (err: any) => {
    let error = new ErrorResponse();
    error.Result = 'Unknown error';
    error.Code = -100;
    error.Message =err.message;

    if (!err.response) {
        return error;
    }
    switch (err.response.status) {
        case 400:
            if (err.response.data.errors) {
                let message = err.response.data.title;
                error.Result = "Bad Request";
                error.Code = 400;
                error.Message = message;
                return error;
            }
            error.Result = err.response.data.result;
            error.Code = err.response.data.code;
            error.Message = err.response.data.message;
            return error;
        case 401:
            error.Result = 'Security Error';
            error.Code = 401;
            error.Message = 'شما دسترسی لازم به این منبع را ندارید';
            return error;
        case 403:
            error.Result = 'Security Error';
            error.Code = 403;
            error.Message = 'شما مجاز به استفاده از این منبع نیستید';
            return error;
        case 404:
            error.Result = 'Error';
            error.Code = 404;
            error.Message = 'منبع مورد نظر یافت نشد';
            return error;
        case 500:
            error.Result = 'Server Error';
            error.Code = 500;
            error.Message = 'خطایی در سرور رخ داده است';
            return error;
        default:
            error.Message = 'خطای ناشناخته';
            return error;

    };

}
